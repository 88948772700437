<template>
  <h3>OffersManager</h3>
</template>

<script>

export default {
  name: "ManagerStoreOffers",
};
</script>
